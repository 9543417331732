import * as yup from 'yup';

export const schemaSpedFiscal = yup
  .object({
    mes: yup.string().required(),
    ano: yup.string().required(),
    tipo_escrituracao: yup
      .object({
        label: yup.string().required(),
        value: yup.number().required(),
      })
      .required()
      .nullable(false),
    num_recibo: yup.string().when('tipo_escrituracao', {
      is: (val: any) => val?.value === 1,
      then: yup.string().required(),
      otherwise: yup.string().notRequired(),
    }),
  })
  .required();
