export const Default = {
  escrituracao: [
    {
      label: 'ORIGINAL',
      value: 0,
    },
    {
      label: 'RETIFICADORA',
      value: 1,
    },
  ],
};
